import Flickity from 'flickity';

const Sliders = (() => {

	const _init = (container => {
		const mqDesktop = window.matchMedia('only screen and (min-width: 1024px)');
		const mqTablet = window.matchMedia('only screen and (min-width: 768px)');
		let options = {
			imagesLoaded: true,
			pageDots: false,
			arrowShape: {
				x0: 30,
				x1: 65, y1: 35,
				x2: 70, y2: 30,
				x3: 40
			}
		}
		const sliders = container.querySelectorAll('[data-js-slider]');

		//console.log(sliders);
		if (sliders) {
			sliders.forEach(el => {
				const sliderType = el.dataset.jsSlider;

				if (!mqTablet.matches) {
					options.pageDots = true;
					options.prevNextButtons = false;
				}
				if (sliderType === 'auto') {
					options.contain = true;
					if (mqDesktop.matches) {
						options.setGallerySize = false;
					}
				}

				if (sliderType === 'content') {
					options.contain = true;
					options.groupCells = 2;
					if (!mqTablet.matches) {
						options.groupCells = 1;
					}
					// options.setGallerySize = true;
				}

				if (sliderType === 'single') {
					options.setGallerySize = true;
				}

				const flkty = new Flickity(el,
					options
				);
			})
		}
	});
	return {
		init: _init
	};
});

export default Sliders;