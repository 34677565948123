const LoadMoreNews = () => {
    const _loadmore = () => {
        if(document.querySelector('.news-content__all-news')) {
            var InfiniteScroll = require('infinite-scroll');

            console.log('test');
            var infScroll = new InfiniteScroll( '.news-content__all-news', {
                path: '.next',     // selector for the paged navigation
                append: '.common-card',     // selector for all items you'll retrieve
                scrollThreshold: false,
                button: '.view-more-button',
                hideNav: '.pagination',
                maxPage: undefined,
                loadOnScroll: false,
                history: false 
            })
        }
    };

    return {
        init: _loadmore
    };
};

export default LoadMoreNews
