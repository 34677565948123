import Swiper from 'swiper';

const CarouselRefProducts = () => {
    const _carouselrefp = () => {
     
        var swiper = new Swiper('.reference-products__slider', {
            slidesPerView: 'auto',
            spaceBetween: 45,
           // loop: true, 
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
        

        
    };

    return {
        init: _carouselrefp
    };
};

export default CarouselRefProducts
