import Swiper, { Pagination, Autoplay } from 'swiper';

Swiper.use([Pagination, Autoplay]);

const CarouselArticle = () => {
    const _carousela = () => {
        
            var swiper = new Swiper('.article-content__carosuel', {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
                },
            });  
    };

    return {
        init: _carousela
    };
};

export default CarouselArticle
