import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay]);

const ReferenceCarousel = () => {
    const _carouselref = () => {
        
            var swiper = new Swiper('.reference-carousel__swiper', {
                speed: 2000,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
   
            });  
    };

    return {
        init: _carouselref
    };
};

export default ReferenceCarousel
