// Dependencies

import imagesLoaded from 'imagesloaded';
import LazyLoad from 'vanilla-lazyload';
//import anime from 'animejs';
//import barba from '@barba/core';
//import LocomotiveScroll from 'locomotive-scroll';

// Main scss import
import styles from '../scss/style.scss';

// Import mobile menu animation for page transition on mobile
import CommonMenu from '../components/common-header/animations';

// Contains main inits
import Init from './common/init';

// Transitions
//import example1 from './transitions/example1';
//import example2 from './transitions/example2';


const init = Init();
const commonMenu = CommonMenu();

// Locomotive Scroll
let scroll;

/*function smooth(container) {
	scroll = new LocomotiveScroll({
		el: container.querySelector('[data-scroll-container]'),
		smooth: true
	});
}

function leavePage(container, done) {
	const headerMenu = document.querySelector('.header__content');
	const btnBurger = document.querySelector('.burger-btn');

	if (headerMenu.classList.contains('header__content--opened')) {
		btnBurger.classList.remove('burger-btn--active');

		commonMenu.init('exit').then(() => headerMenu.classList.remove('header__content--opened'));

	}
	anime({
		targets: container,
		opacity: [1, 0],
		translateY: [0, -20],
		easing: 'easeOutSine',
		duration: 500,
		complete: () => {
			imagesLoaded(container, function () {
				done()
			});
		}
	})
}
barba.hooks.leave(function (data) {
	const done = this.async();
	console.log(data);
	leavePage(data.current.container, done);
});

barba.hooks.beforeLeave(function () {
	scroll.destroy();
});

barba.hooks.beforeEnter(function (data) {
	init.beforeEnter(data.next.container);
	const lazyLoadInstance = new LazyLoad({
		elements_selector: '.lazy',
		callback_reveal: function (el) {
			el.parentNode.classList.add('lazy-block--loaded');
		}
	});
});
barba.hooks.enter(function (data) {
	anime.remove(data.next.container);
	anime({
		targets: data.next.container,
		opacity: [0, 1],
		translateY: [-20, 0],
		easing: 'easeOutSine',
		duration: 500,
		begin: () => {
			smooth(data.next.container);
		}
	});
})
barba.init({
	transitions: [
		{
			name: 'default-transition',
			once(data) {
				imagesLoaded(data.next.container, function () {
					anime({
						targets: data.next.container,
						opacity: [0, 1],
						translateY: [-20, 0],
						easing: 'easeInOutSine',
						duration: 1500,
						begin: () => {
							smooth(data.next.container);
							init.once();
						}
					})
				});
			}
		}
	],
	views: [{
		namespace: 'example-1',
		afterEnter(data) {
			// do something before leaving the current `index` namespace
			example1(data.next.container);
		}
	}, {
		namespace: 'example-2',
		afterEnter(data) {
			// do something before leaving the current `index` namespace
			example2(data.next.container);
		}
	}],
});*/
