import CommonMenu from "../../components/common-header/animations";

const HamburgerMenu = () => {
	const _toggleMenu = () => {
		let hb = document.querySelector('.nav-toggle');
		let menu = document.querySelector('.header__menu');
		
		hb.addEventListener('click', function (event) {
			menu.classList.toggle('open');
			hb.classList.toggle('open');
		
		}, false);

		let scrollpos = window.scrollY
		const header = document.querySelector("header")
		const header_height = header.offsetHeight
	  
		const add_class_on_scroll = () => header.classList.add("fade-in")
		const remove_class_on_scroll = () => header.classList.remove("fade-in")
	  
		window.addEventListener('scroll', function() { 
		  scrollpos = window.scrollY;
	  
		  if (scrollpos >= header_height) { add_class_on_scroll() }
		  else { remove_class_on_scroll() }
	  
		  //console.log(scrollpos)
		})
	};

	return {
		init: _toggleMenu
	};
};

export default HamburgerMenu;