import Swiper from 'swiper';

const CarouselProducts = () => {
    const _carouselp = () => {
        let width = document.body.clientWidth;
        let mode = (width < 1024) ? 'mobile' : 'desktop';
        
        if(mode == 'mobile') {
            var swiper = new Swiper('.home-products__content', {
                slidesPerView: 'auto',
                spaceBetween: 30,
                loop: true, 
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
                },
              });
        }

        
    };

    return {
        init: _carouselp
    };
};

export default CarouselProducts
