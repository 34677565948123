import { checkVersion } from './utils.js';
import charming from 'charming'
const SplitStrings = () => {

	function splitWords(content) {
		content.innerHTML = content.innerHTML.replace(/(\r\n|\n|\r)/gm, ' ');
		content.innerText = content.innerHTML;
		content.innerHTML = content.innerHTML.split(/\s/).map(function (word) {
			return '<span>' + word + '</span>'
		}).join(' ');
	}

	function getLines(content) {
		const lines = [];
		let line;
		const words = content.getElementsByTagName('span');
		let lastTop;
		for (let i = 0; i < words.length; i++) {
			const word = words[i];
			word.innerHTML = word.innerHTML.replace('&lt;strong&gt;', '<strong>');
			word.innerHTML = word.innerHTML.replace('&lt;/strong&gt;', '</strong>');

			if (word.offsetTop != lastTop) {
				lastTop = word.offsetTop;
				line = [];
				lines.push(line);
			}
			line.push(word);
		}
		return lines;
	}

	function splitLines(content) {
		const lines = getLines(content);
		const dataValues = content.dataset.jsSplitLines.split(',');
		const delay = dataValues[0] ? dataValues[0] : 100;
		const isHidden = dataValues[1] ? 'u-overflow-hidden' : '';
		content.innerHTML = ' ';
		lines.forEach((item, index) => {
			const line = item.map(function (span) {
				return span.innerHTML
			}).join(' ');
			const strongsOpened = line.split('<strong>').length - 1;
			const strongsClosed = line.split('</strong>').length - 1;

			if (strongsClosed > strongsOpened) {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms"><strong> ' + line + '</span></span>';
			} else if (strongsClosed < strongsOpened) {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms">' + line + ' </strong></span></span>';
			} else {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms">' + line + '</span></span>';
			}
		});
		// content.querySelectorAll(".line-row__inner:empty").forEach((x) => { console.log(x) });
		// p.innerHTML = p.innerHTML.replace("&lt;strong&gt;", "<strong>");
		// p.innerHTML = p.innerHTML.replace("&lt;/strong&gt;", "</strong>");
		// p.innerHTML = p.innerHTML.replace("&amp;", "&");
	}


	function splitBrLines(content) {
		const dataValues = content.dataset.jsSplitBr.split(',');
		const delay = dataValues[0] ? dataValues[0] : 100;
		const isHidden = dataValues[1] ? 'u-overflow-hidden' : '';
		content.innerText = content.innerHTML;
		content.innerHTML = content.innerHTML.split('&lt;br&gt;').map(function (line) {
			return '<span class="line">' + line + '</span>'
		}).join(' ');
		const lines = document.querySelectorAll('.line');
		content.innerHTML = ' ';

		lines.forEach((line, index) => {
			const strongsOpened = line.innerText.split('<strong>').length - 1;
			const strongsClosed = line.innerText.split('</strong>').length - 1;
			if (strongsClosed > strongsOpened) {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms"><strong> ' + line.innerText + '</span></span>';
			} else if (strongsClosed < strongsOpened) {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms">' + line.innerText + ' </strong></span></span>';
			} else {
				content.innerHTML += '<span class="line-row ' + isHidden + '"><span class="line-row__inner" style="transition-delay:' + index * delay + 'ms">' + line.innerText + '</span></span>';
			}

		});

	}
	const _init = () => {
		const version = checkVersion();
		const mq = window.matchMedia('only screen and (min-width: 1024px)');

		// SPLIT WORDS

		const words = document.querySelectorAll('[data-js-split-words]');
		if (words.length) {
			words.forEach((el) => {
				if (!el.querySelector('.word')) {
					charming(el, {
						split: function (string) {
							return string.split(/\s+/g);
						},
						setClassName: function (index) {
							return `word`;
						},
						tagName: 'span'
					});
					el.querySelectorAll('.word:empty').forEach((x) => { x.remove(); });
				}

			});
		}

		// SPLIT CHARS

		const chars = document.querySelectorAll('[data-js-split-chars]');

		if (chars.length) {
			chars.forEach((el) => {
				if (!el.querySelector('.word')) {
					charming(el, {
						split: function (string) {
							return string.split(/\s+/g)
						},
						setClassName: function () {
							return `word`
						},
						tagName: 'span'
					});
					el.querySelectorAll('.word:empty').forEach((x) => { x.remove(); });
				}
			});

			const wordsSplitted = document.querySelectorAll('.word');
			wordsSplitted.forEach(function (word) {
				charming(word, {
					setClassName: function () {
						return `char`
					}
				});
			});
		}

		// SPLIT LINES WITH <BR/> TAG

		const brLines = document.querySelectorAll('[data-js-split-br]');

		if (brLines && window.innerWidth >= 768 && !version) {
			brLines.forEach((el) => {
				splitBrLines(el);
			})
		}

		// SPLIT LINES AUTOMATICALLY

		const paragraph = document.querySelectorAll('[data-js-split-lines]');

		if (paragraph && !version) {
			paragraph.forEach(el => {
				el.setAttribute('data-content', el.innerHTML);

				const content = el.dataset.content;
				// console.log(borgo);
				setTimeout(function () {
					splitWords(el);
					splitLines(el);
				}, 1000);
				window.addEventListener('resize', () => {
					el.innerHTML = ' ';
					el.innerHTML = content;
					splitWords(el);
					splitLines(el);
				});
			})
		}
	};
	return {
		init: _init
	};
};

export default SplitStrings;