import Sliders from './carousel';
import SplitStrings from '../utilities/splitStrings';
import HamburgerMenu from '../../components/common-header/script';
import CarouselProducts from '../../components/home-products/script';
import CarouselProductType from '../../components/product-carousel/script';
import CarouselArticle from '../../components/article-content/script';
import LoadMoreNews from '../../components/news-content/script';
import ReferenceCarousel from '../../components/reference-carousel/script';
import CarouselRefProducts from '../../components/reference-products/script';

//import Swiper from 'swiper';

const Init = () => {

	const _once = () => {
		//const hm = HamburgerMenu();
		//hm.init();

	};

	const hm = HamburgerMenu();
	hm.init();

	const carouselp = CarouselProducts();
	carouselp.init();

	const carouselrefp = CarouselRefProducts();
	carouselrefp.init();

	const carouselpt = CarouselProductType();
	carouselpt.init();

	const carousela = CarouselArticle();
	carousela.init();

	const loadmore = LoadMoreNews();
	loadmore.init();

	const carouselref = ReferenceCarousel();
	carouselref.init();

	const animations = {};

	if(document.querySelector('.wpcf7-checkbox .wpcf7-list-item-label')) {
		let privacy = document.querySelector('.wpcf7-checkbox .wpcf7-list-item-label');

		if(window.location.href.indexOf("/en/") > -1) { 
			privacy.innerHTML = "We care your data and we will only use them in accordance with Italian and European law: you can read more <a href='/en/privacy-policy/'>here</a> and <a href='/en/privacy-policy/'>accept</a> the privacy policy ";

		}else {
			privacy.innerHTML = "Teniamo ai tuoi dati e li utilizzeremo esclusivamente secondo le normative in vigore in Italia e in Unione Europea: puoi leggere l’informativa <a href='/privacy-policy/'>qui</a>, per poi <a href='/privacy-policy/'>accettare</a> la Privacy Policy";

		}
	}

	animations.CLASSES = {
	RIPPLE: 'ripple',
	RIPPLE_CONTAINER: 'ripple-container',
	RIPPLE_EFFECT_MASK: 'ripple-effect-mask',
	RIPPLE_EFFECT: 'ripple-effect',
	};

	animations.RIPPLE_DURATION_MS = 800;

	animations.RIPPLE_MAX_RADIUS_PX = 300;

	const ripple = (event) => {

	const ev = event.target;

	if (ev.classList.contains('ripple-button')) {
		var target = ev;
	}else {
		var r1 = ev.closest(".ripple-button");
		var target = r1;
	}

	const rect = target.getBoundingClientRect();
	const x = Math.round(event.clientX - rect.left);
	const y = Math.round(event.clientY - rect.top);

	// Calculate radius
	const corners = [
		{x: 0, y: 0},
		{x: rect.width, y: 0},
		{x: 0, y: rect.height},
		{x: rect.width, y: rect.height},
	];
	const distance = (x1, y1, x2, y2) => {
		const xDelta = x1 - x2;
		const yDelta = y1 - y2;
		return Math.sqrt(xDelta * xDelta + yDelta * yDelta);
	};
	const cornerDistances = corners.map(function(corner) {
		return Math.round(distance(x, y, corner.x, corner.y));
	});
	const radius = Math.min(
		animations.RIPPLE_MAX_RADIUS_PX, Math.max.apply(Math, cornerDistances));

	const ripple = document.createElement('div');
	const rippleMask = document.createElement('div');
	const rippleContainer = document.createElement('div');
	ripple.classList.add(animations.CLASSES.RIPPLE_EFFECT);
	rippleMask.classList.add(animations.CLASSES.RIPPLE_EFFECT_MASK);
	rippleContainer.classList.add(animations.CLASSES.RIPPLE_CONTAINER);
	rippleMask.appendChild(ripple);
	rippleContainer.appendChild(rippleMask);
	target.appendChild(rippleContainer);
	// Ripple start location
	ripple.style.marginLeft = x + 'px';
	ripple.style.marginTop = y + 'px';

	rippleMask.style.width = target.offsetWidth + 'px';
	rippleMask.style.height = target.offsetHeight + 'px';
	rippleMask.style.borderRadius =
		window.getComputedStyle(target).borderRadius;

	// Start transition/ripple
	ripple.style.width = radius * 2 + 'px';
	ripple.style.height = radius * 2 + 'px';
	ripple.style.marginLeft = x - radius + 'px';
	ripple.style.marginTop = y - radius + 'px';
	ripple.style.backgroundColor = 'rgba(0, 0, 0, 0)';

	window.setTimeout(function() {
		ripple.remove();
		rippleMask.remove();
		rippleContainer.remove();
	}, animations.RIPPLE_DURATION_MS);
	};

	document.querySelectorAll('.ripple-button').forEach(function(el) {
		el.addEventListener('mousedown', ripple);
	})

	
	//element in viewport
	var isInViewport = function (elem) {
		var distance = elem.getBoundingClientRect();
		return (
			distance.top >= 0 &&
			distance.left >= 0 &&
			distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			distance.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	};

	var sentinelAnimations = document.querySelectorAll('.sentinel-animation');

	sentinelAnimations.forEach(function(el) {
		var parentSentinel = el.parentNode;

		if (isInViewport(el)) {
			parentSentinel.classList.add('in-viewport');
		}
	});

	window.addEventListener('scroll', function (event) {

		sentinelAnimations.forEach(function(el) {
			var parentSentinel = el.parentNode;
	
			if (isInViewport(el)) {
				parentSentinel.classList.add('in-viewport');
			}
		});
	}, false);


	function splitWords() {
		if(document.querySelector(".company-intro__title")) {
			let quote = document.querySelector(".company-intro__title");
			quote.innerText.replace(/(<([^>]+)>)/ig,"");
			let quotewords = quote.innerText.split(" "),
			wordCount = quotewords.length;
			quote.innerHTML = "";
			for (let i=0; i < wordCount; i++) {
			quote.innerHTML += "<span>"+quotewords[i]+"</span>";
			if (i < quotewords.length - 1) {
				quote.innerHTML += " ";
			}
			}
			quotewords = document.querySelectorAll(".company-intro__title span");
			fadeWords(quotewords);
		}
	}
	  
	function getRandom(min, max) {
	return Math.random() * (max - min) + min;
	}
	
	function fadeWords(quotewords) {
	Array.prototype.forEach.call(quotewords, function(word) {
		let animate = word.animate([{
		opacity: 0,
		filter: "blur("+getRandom(2,5)+"px)"
		}, {
		opacity: 1,
		filter: "blur(0px)"
		}], 
		{ 
		duration: 1000,
		delay: getRandom(500,3300),
		fill: 'forwards'
		} 
		)
	})
	}
	  
	  
	splitWords();

	//let width = document.body.clientWidth;

	var elements = document.querySelectorAll('.item');
	
	for(var i=0; i<elements.length; i++) { 
		elements[i].onmouseenter = function(){
			var dataRel = this.getAttribute("data-rel");
			openCity(dataRel);
		};
		elements[i].addEventListener('touchstart', function(e) {
			var dataRel = this.getAttribute("data-rel");
			openCity(dataRel);
		});
	}


	function openCity(cityName) {
		// Declare all variables
		var i, tabcontent, tablinks;
	  
		// Get all elements with class="tabcontent" and hide them
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
		  tabcontent[i].style.opacity = "0";
		}
	  
		// Get all elements with class="tablinks" and remove the class "active"
		tablinks = document.getElementsByClassName("tablinks");
		for (i = 0; i < tablinks.length; i++) {
		  tablinks[i].className = tablinks[i].className.replace(" active", "");
		}
	  
		// Show the current tab, and add an "active" class to the link that opened the tab
		document.getElementById(cityName).style.opacity = "1";
		//evt.currentTarget.className += " active";
	}

	const _beforeEnter = container => {
		const splitStrings = SplitStrings();
		const sliders = Sliders();
		splitStrings.init();
		sliders.init(container);
	};
	const _enter = container => {
		// functions
	};


	return {
		once: _once,
		beforeEnter: _beforeEnter,
		enter: _enter
	}
}

export default Init;