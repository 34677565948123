// Get Next Sibling
export const getNextSibling = (elem, selector) => {
	if (elem) {
		let sibling = elem.nextElementSibling;

		// If there's no selector, return the first sibling
		if (!selector) return sibling;

		// If the sibling matches our selector, use it
		// If not, jump to the next sibling and continue the loop
		while (sibling) {
			if (sibling.matches(selector)) return sibling;
			sibling = sibling.nextElementSibling;
		}
	}
}

// Get Previous Sibling
export const getPrevSibling = (elem, selector) => {
	if (elem) {
		let sibling = elem.previousElementSibling;

		// If there's no selector, return the first sibling
		if (!selector) return sibling;

		// If the sibling matches our selector, use it
		// If not, jump to the next sibling and continue the loop
		while (sibling) {
			if (sibling.matches(selector)) return sibling;
			sibling = sibling.previousElementSibling;
		}
	}
}

// Get translateX, Y and Z
export const getTranslate3d = el => {
	const values = el.style.transform.split(/\w+\(|\);?/);
	if (!values[1] || !values[1].length) {
		return [];
	}
	return values[1].split(/,\s?/g);
}

// Check IE Version
export const checkVersion = () => {
	if (navigator.userAgent.match(/Trident\/7\./)) {
		return true; // IE
	}
	else if (navigator.appName == 'Netscape') {
		return navigator.appVersion.indexOf('Edge') > -1; // EDGE
	}

	return false;
};